import React from "react";
import { useRef } from "react";
import logo from "assets/img/logoAsset.png";
import cn from "classnames";
import { history } from "../../history";
import isEmpty from "lodash/isEmpty";
import { useSelector } from "react-redux";

const Navigation = ({ showJoinUs }) => {
  const appStatic = useSelector((state) => state.appStatic);
  const toggleRef = useRef(null);

  const goTo = (e, path) => {
    e.preventDefault();
    if (history.location.pathname === path && path === "/order-plan") {
      window.__reset_order_plan__();
    }
    if (history.location.pathname === path && path === "/order-meal") {
      window.__reset_meal__();
    }

    if (!toggleRef.current.classList.contains("collapsed")) {
      toggleRef.current.click();
    }

    history.push(path);
  };

  const navCN = cn({
    "navbar navbar-expand-lg navbar-light fixed-top py-3": true,
    mo: showJoinUs
  });

  return (
    <nav className={navCN} id="mainNav">
      <div className="container">
        <a
          className="navbar-brand js-scroll-trigger"
          href="/"
          onClick={(e) => goTo(e, "/")}
        >
          <img src={logo} height="50" alt={"logo"} />
        </a>
        <button
          className="navbar-toggler navbar-toggler-right collapsed"
          type="button"
          data-toggle="collapse"
          data-target="#navbarResponsive"
          aria-controls="navbarResponsive"
          aria-expanded="false"
          aria-label="Toggle navigation"
          ref={toggleRef}
        >
          <span className="navbar-toggler-icon" />
        </button>

        <div className="collapse navbar-collapse" id="navbarResponsive">
          <ul className="navbar-nav ml-auto my-2 my-lg-0 d-md-flex align-items-center">
            <li className="nav-item">
              <a
                className="nav-link"
                href="meal-plans"
                onClick={(e) => goTo(e, "/meal-plans")}
              >
                MEAL PLANS
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                href="weekly-meals"
                onClick={(e) => goTo(e, "/weekly-meals")}
              >
                WEEKLY MENUS
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                href="order-meal"
                onClick={(e) => goTo(e, "/order-meal")}
              >
                À LA CARTE
              </a>
            </li>
            {isEmpty(appStatic.user) ? (
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="login"
                  onClick={(e) => goTo(e, "/login")}
                >
                  LOGIN
                </a>
              </li>
            ) : (
              <li className="nav-item">
                <a className="nav-link" href="/profile/">
                  MY ACCOUNT
                </a>
              </li>
            )}
            <li className="nav-item pl-md-3">
              <a
                className="nav-link btn-secondary"
                href="/order-plan"
                onClick={(e) => goTo(e, "/order-plan")}
              >
                GET STARTED
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navigation;
