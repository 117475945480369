import { combineReducers, createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import appStatic from './appStatic/reducer';
import orderMeal from './orderMeal/reducer';
import meal from './meal/reducer';
import footerInfo from './footerInfo/reducer';
import settings from './settings/reducer';
import subscriptions from './subscriptions/reducer';
import transactions from './transactions/reducer';
import giftCards from './giftCards/reducer';
import cards from './cards/reducer';

import { createBrowserHistory } from 'history';
import { routerReducer, routerMiddleware } from "react-router-redux";

class Store {
    static _persistConfig = {
        key: 'root',
        storage,
        whitelist: ['appStatic'],
        // whitelist: [],
    };

    static _initialReducers = {
        appStatic,
        meal,
        footerInfo,
        orderMeal,
        settings,
        routing: routerReducer,
        subscriptions,
        transactions,
        giftCards,
        cards
    };

    static _perisistedReducers = persistReducer(
        Store._persistConfig,
        combineReducers(Store._initialReducers),
    );

    static init(initialState = {}) {
        const history = createBrowserHistory();

        history.listen((location, action) => {
            console.log(`new location via ${action}`, location);
        });

        const reactRouterMiddleware = routerMiddleware(history);

        const composeEnhancer = typeof window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ !== "undefined" ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({trace: true}) : compose;

        const store = createStore(
            Store._perisistedReducers,
            initialState,
            composeEnhancer(applyMiddleware(thunk, reactRouterMiddleware)),
        );
        const persistor = persistStore(store);
        // persistor.purge(); //
        return { store, persistor, history };
    }
}

export default Store.init();
