import React from 'react';
import logo from 'assets/img/logoAssetText.png';
import { history } from '../../history';
import "styles/footer.scss";

const BottomInfoBlock = () => {
  const goTo = (e, path) => {
    e.preventDefault();
    if (history.location.pathname === path && path === '/gift-card') {
      window.__reset_gift_card__();
    }
    history.push(path);
  }

  return (
    <section className="footer container d-flex flex-wrap">
      <div className="col-md-3">
        <p>
          <img src={logo} width="80" alt={'logo'} />
        </p>
        <p>(213) 831-5758<br /> contact@fitmealworld.com</p>
      </div>

      <div className="col-md-3">
        <h4>Fit Meal World</h4>

        <ul>
          <li>
            <a
              onClick={e => goTo(e, '/terms')}
              href="/terms"
            >Terms Of Use</a>
          </li>
          <li>
            <a onClick={e => goTo(e, '/contact-us')} href="/contact-us">Contact Us</a>
          </li>
          <li>
            <a onClick={e => goTo(e, '/privacy-policy')} href="/">Privacy Policy</a>
          </li>
          <li>
            <a onClick={e => goTo(e, '/faq')} href="/faq">FAQ</a>
          </li>
        </ul>
      </div>

      <div className="col-md-3">
        <h4>Meal Ordering</h4>

        <ul>
          <li>
            <a onClick={e => goTo(e, '/gift-card')} href="/gift-card">Gift Card</a>
          </li>
          <li>
            <a onClick={e => goTo(e, '/how-it-works')} href="/how-it-works">How It Works</a>
          </li>
        </ul>
      </div>
      <div className="col-md-3  text-md-right">
        <p className='text-center'>
          <a onClick={e => goTo(e, '/gift-card')} href="/" className="btn btn-primary">Buy Gift Card</a>
        </p>
      </div>
    </section>
  );
}

export default BottomInfoBlock;
