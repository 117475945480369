const initialState = {
  terms: [],
  faq: [],
  howItWorks: ""
};

const footerInfoReducer = (state = initialState, action) => {
  return {
    ...state,
    ...action.payload
  }
};

export default footerInfoReducer;
